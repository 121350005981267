import { ScrollRestoration } from "react-router-dom";
import styles from "../../styles/privacy.module.css";
import { useEffect, useState } from "react";
import axios from "axios";

const Terms = () => {
  const [data, setData]: [any, any] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios({
      url: "https://api.shopiq.app/api/compliances",
      method: "get",
    })
      .then((res) => {
        let list = res.data.compliances;
        setData(
          list.filter((val: any) => {
            if (val.typeName === "terms") {
              return true;
            }
            return false;
          })[0]
        );
      })
      .catch((err) => {});
  };

  return (
    <>
      <ScrollRestoration />
      <div className={styles.container}>
        <div className={styles.top}>{data?.title}</div>
        <div
          className={styles.content_container}
          dangerouslySetInnerHTML={{
            __html: data?.body.replace(/&nbsp;/g, " "),
          }}
        ></div>
      </div>
    </>
  );
};

export default Terms;
