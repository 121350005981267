import Contact from "../../components/Contact";
import Hero from "../../components/Hero";
import Commitments from "./Commitments";
import Startup from "./Startup";
import fb from "../../assets/checker.png";
// import video from "../../assets/Bion2.webm";
// import video2 from "../../assets/Bion2.mp4";
import { ScrollRestoration, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import styles from "../../styles/services.module.css";
import settingUp from "../../assets/images/settingUp.png";
import accounting from "../../assets/images/accounting.png";
import tax from "../../assets/images/tax.png";
import budget from "../../assets/images/budget.png";
import search from "../../assets/images/search(1).png";
import selectImg from "../../assets/images/select.png";
import management from "../../assets/images/management.png";
import coworking from "../../assets/images/coworking.png";
import ma from "../../assets/images/acquisition.webp";
import { abort } from "process";
import { ADDRGETNETWORKPARAMS } from "dns";

import img1 from "../../assets/images/images/startupAdvisory.jpg";
import img2 from "../../assets/images/images/finance1.jpg";
import img3 from "../../assets/images/images/finance2.jpg";
import axios from "axios";

// interface ServicesProps {}

const Services = () => {
  const [hero, setHero] = useState("");
  const [heroMob, setHeroMob] = useState("");
  const wide = window.innerWidth;

  const location = useLocation().hash;
  const params = useParams();

  useEffect(() => {
    if (location) {
      document.querySelector(location)?.scrollIntoView({ block: "center" });
    }

    axios({
      url: "https://api.shopiq.app/api/contents/?identifier=bionadvisors.com",
      method: "get",
    })
      .then((res: any) => {
        if (res.data.contents[0].hero[1]) {
          setHero(res.data.contents[0].hero[1].mediaUrl);
          setHeroMob(res.data.contents[0].hero[1].mobMediaUrl);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const Card = ({ data }: { data: any }) => {
    const [hover, setHover] = useState(false);

    return (
      <div
        className={styles.card}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <div className={styles.img_container}>
          <img className="grayscale" src={data.img} alt="" />
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            background:
              wide < 500
                ? "rgba(79, 55, 245, .9)"
                : hover
                ? "rgba(79, 55, 245, .9)"
                : "rgba(79, 55, 245, .1)",
            // background: hover
            //   ? "rgba(79, 55, 245, .9)"
            //   : "rgba(79, 55, 245, .1)",
            zIndex: 1,
          }}
          // onMouseEnter={() => {
          //   setHover(true);
          // }}
          // onMouseLeave={() => {
          //   setHover(false);
          // }}
        ></div>
        <p
          className={styles.card_title}
          style={{
            display: wide < 500 ? "none" : hover ? "flex" : "flex",
            opacity: hover ? 0 : 1,
          }}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          {data.title}
        </p>
        <div
          style={{
            zIndex: 10,
            display: wide < 500 ? "flex" : hover ? "flex" : "none",
            // alignItems: wide < 500 ? "center" : hover ? "center" : "flex-end",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          }}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        >
          <ul
            style={{
              display: wide < 500 ? "flex" : hover ? "flex" : "none",
            }}
            className={styles.card_content}
          >
            <p
              className={styles.card_title}
              style={{
                display: wide < 500 ? "block" : "none",
                color: "white",
                whiteSpace: "pre-wrap",
                height: "fit-content",
              }}
            >
              {data.title}
            </p>
            {data.li.map((it: any, idx: number) => (
              <li className="opacity-100" key={idx}>
                {it}
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <>
      <ScrollRestoration />
      <Hero
        text="YOUR FINANCE TEAM"
        styless={{
          color: "#ABA4A4",
          width: "100vw",
          textAlign: "center",
        }}
        videoSrc={window.innerWidth < 800 ? hero : heroMob}
      />
      <Startup
        id={"startup"}
        bg="#4f37f5"
        text={`We help tech founders with the number crunching and financial nitty gritty involved in getting your startup off the ground.

		We’ll help in building financial projections, understanding your cash burn-rate, expense and spending patterns, refining your revenue model, selecting the optimum entity structure, etc.

		Hereafter, we shall step in as a finance CFO where needed - supporting your pitching efforts and stepping into answer investors’ queries, front-ending the valuation process, facing negotiations, and helping you through due diligence rounds to secure funding.`}
        title="Startup advisory"
        img={img1}
      />
      <Startup
        id={"control"}
        rev={true}
        title="Finance Controllership"
        text={`Our virtual CFO services team takes care of your routine and day-to-day finance requirements – including book-keeping, accounting, taxes, payroll & basic HR, labour laws, regulatory filings, and any related compliances you are required to keep up with.

		Outsource your finance department to our capable and efficient team!`}
        img={img2}
      />
      <Startup
        id="strategy"
        bg={"rgba(79, 55, 245, 0.7)"}
        rev={true}
        title="Finance Strategy"
        text={`We’re here to help you make larger financial decisions like operational expansion, budgeting and forecasting, tax-efficient expansion strategies, ESOPs, working capital and cashflow management, capital budgeting decisions, investor and lender reporting, planning exit strategies etc.

		Our expert knowledge is just a call away!`}
        img={img3}
      />
      <div
        id="service"
        className="px-6 md:px-0 py-4 md:py-10 flex flex-col md:flex-row md:items-center gap-y-4 md:gap-y-0 gap-x-6 md:gap-x-0"
      >
        <p className="md:-rotate-90 text-4xl md:text-7xl tracking-wide ml-0 md:ml-12">
          Services
        </p>
        <div className={styles.service_card_container}>
          <div
            // className={`grid grid-cols-2 xl:grid xl:grid-cols-4 flex-wrap items-start gap-4 gap-y-8 mt-4 mb-4 lg:mr-8 ${styles.card_container}`}
            className={styles.card_container}
          >
            {data.map((step: any, index: number) => {
              return <Card key={index} data={step} />;
            })}
          </div>
        </div>
      </div>
      <Commitments />
      <Contact />
    </>
  );
};

export default Services;

const data = [
  {
    title: "Setting up",
    li: [
      "Entity selection and formation",
      "Statutory clearances and licenses",
      "Setting up accounting system",
      "Setting up of selected revenue model and other plans as per startup advisory",
    ],
    img: settingUp,
  },
  {
    title: "Book-keeping and accounting",
    li: [
      "End-to-end accounting",
      "Payables and Receivables management",
      "Co-ordination with employees, vendors and banks",
      "MIS reports",
      "Financial statements preparation",
    ],
    img: accounting,
  },
  {
    title: "Tax and regulatory",
    li: [
      "Compliance and return-filing for Income-tax, GST and TDS",
      "International taxation, FEMA and Company Law advisory & compliance",
      "Litigation and representation services",
      "Tax planning strategies and advice",
    ],
    img: tax,
  },
  {
    title: "Financial planning and Analysis",
    li: [
      "Cost, variance, and financial performance analysis",
      "Forecasting and budgeting",
      "Performance measurement and reporting",
      "Capital budgeting and investment decision-making",
    ],
    img: budget,
  },
  {
    title: "M&A",
    li: [
      "Transaction support services",
      "Due diligence support",
      "Valuation",
      "Financial modelling",
      "Regulatory compliance and approvals",
      "Deal structuring and negotiation",
      "Post-merger integration",
      "Exit strategy planning",
    ],
    img: ma,
  },
  {
    title: "Audit and assurance",
    li: [
      "Statutory audit support  and coordination",
      "Tax Audit, GST Audit, and Transfer Pricing Audit",
      "Internal Audit and Concurrent Audit",
      "Limited reviews",
      "Other Certifications",
    ],
    img: search,
  },
  {
    title: "Payroll & HR",
    li: [
      "Monthly Salary computation and processing with payslips",
      "Statutory compliances - TDS, PT, PF, ESI",
      "Leave management",
      "Employee onboarding and exit formalities",
      "ESOPs and employee policies",
    ],
    img: selectImg,
  },
  {
    title: "Individual services",
    li: [
      "Salary Structuring and Tax Planning",
      "Income-tax return filing",
      "Representation before IT authorities",
      "Comprehensive tax advisory - Capital gains, NRI funds repatriation, freelancer / consultant income, etc.",
    ],
    img: management,
  },
  {
    title: "Virtual Office & Co-working Spaces",
    li: [
      "Premium address for business",
      "Courier handling and forwarding",
      "Flexible co-working spaces",
      "Conference room access",
      "Assistance with business setting up",
    ],
    img: coworking,
  },
];
