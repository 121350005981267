import React, { useEffect, useState } from "react";
import { ScrollRestoration } from "react-router-dom";
import Hero from "../../components/Hero";
import Global1 from "../../assets/images/images/global.jpeg";
import Contact from "../../components/Contact";
import preincorp from "../../assets/images/preincorp.webp";
import entitysetup from "../../assets/images/entitysetup.webp";
import axios from "axios";

const Global = () => {
  const [hero, setHero] = useState("");
  const [heroMob, setHeroMob] = useState("");

  const width = window.innerWidth;

  useEffect(() => {
    axios({
      url: "https://api.shopiq.app/api/contents/?identifier=bionadvisors.com",
      method: "get",
    })
      .then((res: any) => {
        if (res.data.contents[0].hero[2]) {
          setHero(res.data.contents[0].hero[2].mediaUrl);
          setHeroMob(res.data.contents[0].hero[2].mobMediaUrl);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <ScrollRestoration />
      <Hero
        text="YOUR PARTNERS FOR
      GLOBAL EXPANSION"
        fontSize="60px"
        videoSrc={window.innerWidth < 800 ? hero : heroMob}
        styless={{
          fontSize: "40px",
          color: "#FFF",
          width: `${width < 1280 ? "100%" : "60%"}`,
          textAlign: `${width < 800 ? "left" : "right"}`,
          lineHeight: "1.2",
        }}
        isGlobalPage={true}
      />
      <section className="flex flex-col items-center justify-center w-full h-fit py-16 bg-[#fefefa] gap-16">
        <div
          className="flex flex-col h-fit justify-center items-center w-[80%] gap-6"
          style={{
            fontFamily: "Josefin Sans, sans-serif",
          }}
        >
          <h1 className="text-4xl text-center text-[#BB9C71]">BRIDGE DUBAI</h1>
          <span className="text-sm text-center text-[#464646]">
            We are your window to Dubai! We specialize in simplifying the
            intricate process of market entry into Dubai.
          </span>
        </div>
        <div className="flex flex-col  items-center justify-center w-[80%] md:w-[60%] gap-16">
          <div className="flex flex-col md:flex-row items-center justify-center w-full  h-full md:h-[35vh] bg-[#fefefa] ">
            <img
              src={preincorp}
              alt="card"
              className="w-full md:w-1/4 h-full object-cover"
            />
            <p
              className="w-full md:w-3/4 h-full text-[#F9F1D8] bg-[#BB9C71] flex flex-col items-center justify-center gap-3 text-base py-10 md:py-0"
              style={{
                fontFamily: "Josefin Sans, sans-serif",
              }}
            >
              <span className="w-4/5 text-left text-2xl text-[#fefefa]">
                Pre-Incorporation Advisory Services
              </span>
              <p className="text-justify w-4/5">
                These are essential for laying a strong foundation for your
                business and ensuring that you have a well-thought-out plan in
                place before officially launching your entity. We can help with
                market research, feasibility studies, business plan development,
                financial modelling, and overall operational and business
                strategies.
              </p>
            </p>
          </div>
          <div className="flex flex-col md:flex-row-reverse items-center justify-center w-full h-full md:h-[35vh] bg-[#fefefa]">
            <img
              src={entitysetup}
              alt="card"
              className="w-full md:w-1/4 h-full object-cover"
            />
            <p
              className="w-full md:w-3/4 h-full text-[#F9F1D8] bg-[#BB9C71] flex flex-col items-center justify-center gap-3 text-base py-10 md:py-0"
              style={{
                fontFamily: "Josefin Sans, sans-serif",
              }}
            >
              <span className="w-4/5 text-left text-2xl text-[#fefefa]">
                Entity Set-up Services
              </span>
              <p className="text-justify w-4/5">
                We can help you in navigating regulatory compliances and
                securing the necessary licenses to ensure timely incorporation
                and set-up of your Dubai entity.
              </p>
            </p>
          </div>
        </div>
        <button className="text-[#BB9C71] bg-[#fefefa] border-2 border-solid border-[#BB9C71] py-2 px-4 rounded-sm text-base w-fit h-fit hover:bg-[#BB9C71] hover:text-[#fefefa] cursor-pointer">
          <a href="/services">View All Services</a>
        </button>
      </section>

      <section className="bg-[#BB9C71] h-fit p-10 md:pt-20 md:pl-20 md:pr-0 md:pb-0 flex">
        <div className="w-full h-full flex flex-col md:flex-row items-center justify-center md:justify-end gap-6 md:gap-1">
          <p
            className="w-full lg:w-[33%] h-full text-[#F9F1D8] flex flex-col items-start justify-center lg:gap-2
          text-left "
            style={{
              fontFamily: "Josefin Sans, sans-serif",
            }}
          >
            <h1 className="text-4xl uppercase w-full text-[#fefefa]">
              Real estate
              <br />
              Consulting
            </h1>
            <p className="text-justify text-base w-[90%]">
              Dubai’s real estate market is a land of opportunity. As your
              consultants, we bridge the gap between your investment goals and
              the vibrant Dubai property market
              <br />
              <br />
              Let's unlock Dubai's potential together.
            </p>
          </p>
          <img
            src={Global1}
            alt="card"
            className="w-full md:w-3/5 h-[200px] md:h-[30vw] object-cover"
          />
        </div>
      </section>
      <Contact />
    </>
  );
};

export default Global;
