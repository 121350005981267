import { ScrollRestoration } from "react-router-dom";
import styles from "../../styles/blog.module.css";
import Card from "./Card";
import { getAllBlogs } from "./apiCall";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

// interface BlogProps {}

export const imageLink = "";

const Blog = () => {
  const [blog, setBlog] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [page, setPage] = useState<any>(1);
  const [total, setTotal] = useState(0);
  const [productLength, setProductLength] = useState(0);

  useEffect(() => {
    console.log("fetching blog");

    setLoadingPage(true);

    getAllBlogs(page)
      .then((data) => {
        setTotal(data.totalLiveCount);

        setProductLength(
          [
            ...blog,
            ...data.blogs.filter((val: any) => {
              if (val.status === "draft") {
                return false;
              }
              return true;
            }),
          ].length
        );

        setBlog(
          [
            ...blog,
            ...data.blogs.filter((val: any) => {
              if (val.status === "draft") {
                return false;
              }
              return true;
            }),
          ].sort((a: any, b: any) => a.priority - b.priority)
        );

        setLoading(false);

        setLoadingPage(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        setLoadingPage(false);
      });
  }, [page]);

  const fetchMoreProducts = () => {
    setPage((prev: any) => prev + 1);
  };

  return (
    <>
      {loading ? (
        <>
          <div
            style={{
              height: "100vh",
              width: "100vw",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span className={styles.loader}></span>
          </div>
        </>
      ) : (
        <>
          <ScrollRestoration />

          {blog?.length !== 0 ? (
            <div className={`mt-16 ${styles.hero_container}`}>
              <img
                src={`${blog[0].image}`}
                alt=""
                className="w-full h-[80vh] object-cover"
              />
              <div>
                <h1>Insights</h1>
              </div>
            </div>
          ) : (
            <div
              style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Blog not found</p>
            </div>
          )}
          {blog?.length !== 0 && (
            <>
              <InfiniteScroll
                dataLength={productLength}
                next={fetchMoreProducts}
                hasMore={productLength !== total ? true : false}
                loader={
                  <div
                    style={{
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span className="loader"></span>
                  </div>
                }
              >
                <div className={styles.grid_container}>
                  {blog.map((step: any, index: number) => {
                    return (
                      <Card
                        key={index}
                        src={step.image}
                        id={step.slug}
                        desc={step.title}
                        text={step.description}
                      />
                    );
                  })}
                </div>
              </InfiniteScroll>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "30px",
                }}
              >
                {loadingPage && <span className={styles.loader}></span>}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Blog;
