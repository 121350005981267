import { useEffect, useState } from "react";
import Hero from "../../components/Hero";
import Detail from "./Detail";
import Values from "./Values";
// import video from "../../assets/Bion2.webm";
// import video2 from "../../assets/Bion2.mp4";
import { ScrollRestoration } from "react-router-dom";
import axios from "axios";

// interface TeamProps {}

const Team = () => {
  const [hero, setHero] = useState("");
  const [heroMob, setHeroMob] = useState("");

  useEffect(() => {
    axios({
      url: "https://api.shopiq.app/api/contents/?identifier=bionadvisors.com",
      method: "get",
    })
      .then((res: any) => {
        if (res.data.contents[0].hero[3]) {
          setHero(res.data.contents[0].hero[3].mediaUrl);
          setHeroMob(res.data.contents[0].hero[3].mobMediaUrl);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <ScrollRestoration />
      <Hero
        text="OUR MISSION"
        isCompanyPage={true}
        videoSrc={window.innerWidth < 800 ? hero : heroMob}
        styless={{ color: "#303030" }}
      />
      <Values />
      <Detail />
    </>
  );
};

export default Team;
