import ApplyNow from "../../components/ApplyNow";
import Hero from "../../components/Hero";
import Culture from "./Culture";
import Opening from "./Opening";
import { ScrollRestoration } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllJobs } from "./apiCalls";
import axios from "axios";

// interface CareerProps {}

const Career = () => {
  const [hero, setHero] = useState("");
  const [heroMob, setHeroMob] = useState("");
  const [opening, setOpening]: [any, any] = useState([]);

  useEffect(() => {
    axios({
      url: "https://api.shopiq.app/api/contents/?identifier=bionadvisors.com",
      method: "get",
    })
      .then((res: any) => {
        if (res.data.contents[0].hero[4]) {
          setHero(res.data.contents[0].hero[4].mediaUrl);
          setHeroMob(res.data.contents[0].hero[4].mobMediaUrl);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });

    getAllJobs()
      .then((res) => {
        // testing
        setOpening([...res]);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      <ScrollRestoration />
      <Hero
        text="Career"
        videoSrc={window.innerWidth < 800 ? hero : heroMob}
        smallHeight={true}
      />
      <Culture />
      {opening.length !== 0 && <Opening data={opening} />}
      <ApplyNow openings={opening} />
    </>
  );
};

export default Career;
